var geoCoordMap = {
  '中国农业科学院麻类研究所': [112.89708431206891, 28.215208345690932],
  '龙之梦臻品酒店': [112.99176610566388, 28.196860105895862],
  '麓谷林语c区': [112.86757521393805, 28.22890113054207],
  '麓谷林语E-1区': [112.87490224963537, 28.227156232906104],
  '麓谷林语BⅡ区': [112.8711577325996, 28.225806216734682],
  '龙骧集团': [113.00256316794182, 28.19955509585358],
  '龙湾壹号': [112.98787389638836, 28.253629646009944],
  '龙竹苑小区': [112.90385644570027, 28.205814020811914],
  '麓谷星辰': [112.85727525404215, 28.21984433111654],
  '麓谷明珠': [112.88781954253187, 28.207690063536347],
  '麓谷新世界': [112.85674484919257, 28.205020779861957],
  '麓谷小镇': [112.891472, 28.238239],
  '麓谷信息港': [112.897824, 28.220504],
  '建安像素汇': [112.898938, 28.219597],
  '长沙软件园': [112.899333, 28.220648],
  '麓谷小学': [112.88684696797736, 28.21690336971796],
  '麓谷坐标': [112.88898817900363, 28.216899695499354],
  '五矿科技产业园一期': [112.900303, 28.228788],
  '五矿科技产业园二期': [112.903825, 28.227801],
  '银河科技园': [112.906484, 28.226098],
  '金荣·央谷金座': [112.902747, 28.222947],
  '麓谷国际工业园': [112.86891870868178, 28.222378021382337],
  '麓谷和馨园': [112.85230074390955, 28.214894676516295],
  '麓谷企业广场': [112.880354547264, 28.2121464340884],
  '麓谷·沐春园': [112.8989535105798, 28.220252892897978],
  '麓景裕园': [112.89596859160093, 28.210829085715538],
  '麓山润城': [112.87824948015889, 28.203338409524],
  '麓山枫情': [112.905646865273, 28.205975581567007],
  '鹏基·诺亚山林': [112.98729410820194, 28.205952748917944],
  '鸿铭中心': [112.99402084912596, 28.18669811966935],
  '鸿江苑': [112.97086924944637, 28.267074476923156],
  '鸿景雅苑': [112.9847020031389, 28.18648370047947],
  '鸿信大厦': [112.96946034147378, 28.186497013222162],
  '鸡鸭恋食堂': [112.97938619284453, 28.184578138239452],
  '高鑫麓城': [112.9224723352201, 28.221317112003604],
  '高桥现代商贸城': [113.0181466731061, 28.176962498248866],
  '高晟华成': [112.88004583462049, 28.20228406844622],
  '高新区地税局': [112.88938656328259, 28.219695260200634],
  '高信向日葵中区': [112.92024742161556, 28.21440777308867],
  '骏达大厦': [112.83942548978376, 28.202282664946534],
  '香海·西岸': [112.95071879950294, 28.232282599547904],
  '香泽南湖大厦': [113.00477919084878, 28.20676577180484],
  '香樟鑫都': [113.02332479000673, 28.14034621999733],
  '香樟景苑': [113.01563979400574, 28.140389617266433],
  '香樟·兰亭': [113.0223937491106, 28.141662334185153],
  '香槟花苑': [113.07927411218313, 28.235668678977543],
  '香槟小镇': [113.07943510105208, 28.23504201968422],//香槟小镇停车场
  '湘雅三医院': [112.951219, 28.226997],
  '中南大学': [112.948147, 28.226679],
  '中联科技园': [112.95686, 28.226647],
  '阳光城': [112.952549, 28.224006],
  '英才园': [112.952153, 28.223051],
  '湘腾城市广场': [112.942164, 28.22582],
  '省肿瘤医院': [112.944069, 28.221364],
  '长沙西中心': [112.920156, 28.21503],
  '广厦新苑': [112.918126, 28.212706],
  '南望商务酒店': [112.918126, 28.212706],
  '达美D6区': [112.920264, 28.208441],
  '旭辉御府': [112.875743, 28.195261],
  '梅溪青秀': [112.880702, 28.193861],
  '长郡梅溪湖中学': [112.883523, 28.196041],
  '好莱城': [112.883613, 28.200307],
  '长沙市政府': [112.94538, 28.234572],
  '八方小区': [112.94538, 28.234572],
  '岳麓区政府': [112.94538, 28.234572],
  '绿地中央广场': [112.957633, 28.234254],
  '世茂泊翠湾': [112.965682, 28.233907],
  '奥克斯广场': [112.961406, 28.230152],
  '中电软件园': [112.888787, 28.236545],
  '华时捷科技园': [112.885301, 28.236036],
  '芯城科技园': [112.882103, 28.235017],
  '潇湘奥林匹克': [112.879768, 28.236863],
  '辰泰科技园': [112.883289, 28.237277],
  '长沙市第四医院': [112.956088, 28.200228],
  '中医药研究院': [112.956555, 28.201199],
  '枫林宾馆': [112.956402, 28.202671],
  '青枫大厦': [112.957965, 28.199762],
  '丽山大酒店': [112.959376, 28.201879],
  '梅溪湖国际': [112.913293, 28.202794],
  '金茂广场': [112.909771, 28.202556],
  '文化艺术中心': [112.90749, 28.202237],
  '金茂府': [112.906555, 28.200677],
  '梅溪湖国际新城': [112.903519, 28.198624],
  '中建梅溪湖中心': [112.901938, 28.19786],
  '中建梅澜坊': [112.901938, 28.19786],
  '银杏公园': [112.892524, 28.194326],
  '晟通牡丹舸': [112.891212, 28.192798],
  '和泓梅溪四季': [112.888284, 28.195488],
  '湖南商学院': [112.925564, 28.224956],
  '荷塘小区': [112.922545, 28.224574],
  '金盛建材批发市场': [112.922384, 28.223492],
  '金谷大厦': [112.918629, 28.225736],
  '望城坡环球家居超市': [112.918054, 28.221758],
  '长沙鸿天酒店': [112.961873, 28.19895],
  '岳麓广场': [112.960813, 28.202564],
  '五一广场': [112.983917, 28.202166],
  '名汇达商务楼': [112.985427, 28.20008],
  '嘉顿新天地': [112.981779, 28.200717],
  '万代大酒店': [112.982336, 28.202325],
  '新大新大厦': [112.983612, 28.203646],
  '苏宁易购': [112.983378, 28.199348],
  '天心区人民医院': [112.981097, 28.199444],
  '三王丽都': [112.978977, 28.197088],
  '华远华中心': [112.978977, 28.197088],
  '下河街大市场': [112.976372, 28.198743],
  '银河大厦': [112.983217, 28.195751],
  '黄兴广场': [112.982543, 28.193793],
  '西罗亚商厦': [112.983172, 28.192822],
  '化龙池': [112.984142, 28.192496],
  '市中医医院': [112.979983, 28.188962],
  '湘亚大厦': [112.979246, 28.188611],
  '冶金大厦': [112.978752, 28.187346],
  '裕园小区': [112.978752, 28.187346],
  '景泰雅园': [112.976938, 28.187043],
  '金满地商业街': [112.982256, 28.206654],
  '长沙青少年宫': [112.983513, 28.206829],
  '万达总部国际': [112.977746, 28.207418],
  '潮宗御苑': [112.978177, 28.210012],
  '湖南中医附二': [112.98752, 28.209137],
  '风帆广场': [112.979165, 28.215599],
  '复地崑玉国际': [112.982202, 28.216013],
  '华盛新外滩': [112.981519, 28.217222],
  '长沙市中心医院': [112.986819, 28.216315],
  '兴汉华庭': [112.988813, 28.215647],
  '湘雅医学院': [112.989226, 28.221758],
  '维也纳国际酒店1': [112.986783, 28.224463],
  'CFC富兴时代': [112.994904, 28.221471],
  '锦绣华天': [112.992514, 28.224559],
  '北辰三角洲': [112.985148, 28.238991],
  '北辰三角洲A区': [112.985076, 28.244099],
  '北辰三角洲C区': [112.993161, 28.243669],
  '科大佳园-南区': [113.007534, 28.241219],
  '科大佳园-北区': [113.008199, 28.245324],
  '四方商贸城': [113.015511, 28.241394],
  '香樟雅郡': [113.011936, 28.238355],
  '世纪金源': [112.985741, 28.257479],
  '顺天黄金海岸': [112.991382, 28.255633],
  '顺天北国风光': [112.99449, 28.256556],
  '湘江世纪城': [112.984771, 28.268312],
  '恒大御景': [112.989496, 28.268853],
  '世界之窗': [113.059061, 28.240567],
  '中南汽车世界': [113.072535, 28.241012],
  '星沙汽配城': [113.066786, 28.231083],
  '长沙火车站': [113.020254, 28.199889],
  '锦泰广场': [113.024242, 28.199507],
  '华瑞大厦': [113.015673, 28.197056],
  '阿波罗商业广场': [113.016283, 28.202659],
  '三湘南湖大市场': [113.020326, 28.205365],
  '长沙大厦': [113.012582, 28.199698],
  '华天大酒店': [113.005288, 28.196929],
  '科佳商业广场': [113.013732, 28.194923],
  '友阿总部': [113.014235, 28.19244],
  '高桥建材市场': [113.022769, 28.189001],
  '维也纳国际酒店2': [113.024566, 28.184703],
  '高桥大市场': [113.023955, 28.179418],
  '火车南站': [113.071493, 28.152986],
  '红星井湾子家居广场': [113.012493, 28.131805],
  '星语林': [113.009384, 28.129846],
  '鑫远国际': [112.984825, 28.11723],
  '保利花园': [112.976956, 28.117421],
  '湘水熙园': [112.97586, 28.12263],
  '鑫远香山': [112.988867, 28.109918],
  '湘雅二医院': [113.000437, 28.19365],
  '长沙市传染病医院': [112.995838, 28.193459],
  '定王大厦': [112.993107, 28.194796],
  '汇富中心': [113.003348, 28.189957],
  '贺龙体育场': [112.989622, 28.183398],
  '友谊商城': [113.001803, 28.1741],
  '国贸金融中心': [112.991454, 28.203232],
  '运达国际广场': [112.991885, 28.208707],
  '银华大厦': [112.99555, 28.201099],
  '维也纳酒店': [112.993395, 28.2117],
  '湖南省体育场': [112.996269, 28.212654],
  '泊富国际广场': [112.990736, 28.214755],
  '绿地中心': [112.994113, 28.216697],
  '马王堆商贸城': [113.038004, 28.200797],
  '好润佳购物中心': [113.03831, 28.207832],
  '湖南环保涂料城': [113.032022, 28.20554],
  '马王堆防水建材市场': [113.041975, 28.205413],
  '万家丽小区': [113.03522, 28.209042],
  '君悦香邸': [112.992029, 28.237018],
  '长沙丽星汽车维修服务有限公司': [112.994904, 28.240773],
  '北辰府': [112.989155, 28.246358],
  '湖南省儿童医院': [112.995586, 28.179736],
  '光大发展大厦': [112.990915, 28.174196],
  '汇金国际银座': [112.991346, 28.1684],
  '湖南省第二医院': [112.991418, 28.161585],
  '顺天国际金融中心': [113.001839, 28.164324],
  '湖南邮电职业技术学院': [112.982363, 28.166585],
  '中建江山一号': [112.973596, 28.166903],
  '保利国际广场': [112.975321, 28.172381],
  '荣盛花语书苑': [112.98301, 28.164292],
  '望月公园': [112.959115, 28.217779],
  '圆泰国际广场': [112.966158, 28.221853],
  '望月湖文明小区': [112.963283, 28.209853],
  '长沙玛丽亚妇产医院': [112.96084, 28.225004],
  '岳麓区政府': [112.937268, 28.240535],
  '长沙银行': [112.957606, 28.250081],
  '阳光丽城': [112.957606, 28.253263],
  '钰龙天下': [112.942658, 28.250972],
  '滨江农贸综合市场': [112.957462, 28.243781],
  '宇洋西海广场': [112.940179, 28.217365],
  '长沙华文香枫酒店': [112.936047, 28.210776],
  '万科城': [113.002162, 28.252308],
  '万国城': [113.006402, 28.260199],
  '美利新世界': [113.027391, 28.256559],
  '长沙大学附属中学': [113.029691, 28.257339],

  '金麓国际商务广场': [112.942304, 28.230672],
  '长沙湘江爱尔眼科医院': [112.928182, 28.226376],
  '咸嘉新村': [112.935225, 28.226949],
  '润泽园': [112.931488, 28.22157],
  '新天地大厦': [112.939681, 28.22192],
  '湖南财经学院': [112.931955, 28.211257],
  '步步高生活广场': [113.015498, 28.218101],
  '王府花园': [113.015713, 28.216477],
  '南湖建材城': [113.018264, 28.220488],
  '毛家桥大市场': [113.028074, 28.242065],
  '长城万富汇': [113.024948, 28.243115],
  '麦德龙': [113.018372, 28.241142],
  '麓山国际': [112.957934, 28.212594],
  '长沙教育学院': [112.96, 28.208854],
  '岳麓区人力资源市场': [112.964079, 28.217368],
  '景秀江山': [112.961366, 28.217209],
  '莱茵城': [112.916217, 28.234937],
  '欣胜园': [112.917798, 28.230259],
  '时代广场': [112.916145, 28.226185],
  '奥克斯缔壹城': [112.92193, 28.239488],
  '阳光晶城': [112.913199, 28.238151],
  '湖南中医药附一': [113.002131, 28.171046],
  '蓝湖国际广场': [112.978119, 28.17115],

  '沁园春': [112.964573, 28.219358],
  '华润城市海岸': [112.964519, 28.22149],
  '湖韵佳苑': [112.954943, 28.212578],
  '凯乐国际城': [112.983419, 28.224753],
  '金色屋顶': [112.981479, 28.223718],
  '古汉国际广场': [113.031568, 28.209777]
}
var value = [
  { name: '中国农业科学院麻类研究所', value: 200, state: 1 },
  { name: '龙之梦臻品酒店', value: 220, state: -1 },
  { name: '麓谷林语c区', value: 230, state: -1 },
  { name: '麓谷林语E-1区', value: 222, state: -1 },
  { name: '麓谷林语BⅡ区', value: 244, state: -1 },
  { name: '龙骧集团', value: 200, state: 1 },
  { name: '龙湾壹号', value: 256, state: -1 },
  { name: '龙竹苑小区', value: 299, state: -1 },
  { name: '麓谷星辰', value: 122, state: -1 },
  { name: '麓谷明珠', value: 200, state: -1 },
  { name: '麓谷新世界', value: 240, state: 1 },
  { name: '麓谷小镇', value: 240, state: -1 },
  { name: '麓谷信息港', value: 240, state: 1 },
  { name: '建安像素汇', value: 280, state: 1 },
  { name: '长沙软件园', value: 280, state: 1 },
  { name: '麓谷小学', value: 143, state: 1 },
  { name: '麓谷坐标', value: 299, state: 1 },
  { name: '五矿科技产业园一期', value: 299, state: 1 },
  { name: '五矿科技产业园二期', value: 299, state: 1 },
  { name: '银河科技园', value: 299, state: 1 },
  { name: '金荣·央谷金座', value: 299, state: 1 },
  { name: '麓谷国际工业园', value: 211, state: 1 },
  { name: '麓谷和馨园', value: 233, state: -1 },
  { name: '麓谷企业广场', value: 255, state: 1 },
  { name: '麓谷·沐春园', value: 235, state: -1 },
  { name: '麓景裕园', value: 210, state: -1 },
  { name: '麓山润城', value: 229, state: -1 },
  { name: '麓山枫情', value: 200, state: -1 },
  { name: '鹏基·诺亚山林', value: 220, state: -1 },
  { name: '鸿铭中心', value: 230, state: 1 },
  { name: '鸿江苑', value: 222, state: -1 },
  { name: '鸿景雅苑', value: 244, state: -1 },
  { name: '鸿信大厦', value: 200, state: 1 },
  { name: '鸡鸭恋食堂', value: 156, state: 1 },
  { name: '高鑫麓城', value: 299, state: -1 },
  { name: '高桥现代商贸城', value: 222, state: 1 },
  { name: '高晟华成', value: 200, state: -1 },
  { name: '高新区地税局', value: 200, state: 1 },
  { name: '高信向日葵中区', value: 220, state: 1 },
  { name: '骏达大厦', value: 230, state: 1 },
  { name: '香海·西岸', value: 222, state: -1 },
  { name: '香泽南湖大厦', value: 244, state: -1 },
  { name: '香樟鑫都', value: 200, state: -1 },
  { name: '香樟景苑', value: 256, state: -1 },
  { name: '香樟·兰亭', value: 199, state: -1 },
  { name: '香槟花苑', value: 222, state: -1 },
  { name: '香槟小镇', value: 200, state: -1 },
  { name: '湘雅三医院', value: 290, state: 1 },
  { name: '中南大学', value: 290, state: 1 },
  { name: '中联科技园', value: 222, state: 1 },
  { name: '阳光城', value: 244, state: -1 },
  { name: '英才园', value: 200, state: -1 },
  { name: '湘腾城市广场', value: 156, state: 1 },
  { name: '省肿瘤医院', value: 299, state: 1 },
  { name: '长沙西中心', value: 222, state: 1 },
  { name: '广厦新苑', value: 200, state: -1 },
  { name: '南望商务酒店', value: 200, state: -1 },
  { name: '达美D6区', value: 220, state: -1 },
  { name: '旭辉·御府', value: 230, state: -1 },
  { name: '梅溪青秀', value: 222, state: -1 },
  { name: '长郡梅溪湖中学', value: 244, state: 1 },
  { name: '好莱城', value: 200, state: -1 },
  { name: '长沙市政府', value: 256, state: 1 },
  { name: '八方小区', value: 199, state: -1 },
  { name: '岳麓区政府', value: 222, state: 1 },
  { name: '绿地中央广场', value: 200, state: 1 },
  { name: '世茂泊翠湾', value: 256, state: -1 },
  { name: '奥克斯广场', value: 199, state: 1 },
  { name: '中电软件园', value: 222, state: 1 },
  { name: '华时捷科技园', value: 200, state: 1 },
  { name: '芯城科技园', value: 290, state: 1 },
  { name: '潇湘奥林匹克', value: 290, state: 1 },
  { name: '辰泰科技园', value: 222, state: 1 },
  { name: '长沙市第四医院', value: 244, state: 1 },
  { name: '中医药研究院', value: 200, state: 1 },
  { name: '枫林宾馆', value: 156, state: -1 },
  { name: '青枫大厦', value: 299, state: 1 },
  { name: '丽山大酒店', value: 222, state: -1 },
  { name: '梅溪湖国际', value: 200, state: -1 },
  { name: '金茂广场', value: 200, state: 1 },
  { name: '文化艺术中心', value: 220, state: 1 },
  { name: '金茂府', value: 230, state: -1 },
  { name: '梅溪湖国际新城', value: 222, state: -1 },
  { name: '中建梅溪湖中心', value: 244, state: -1 },
  { name: '中建梅澜坊', value: 200, state: -1 },
  { name: '银杏公园', value: 256, state: 1 },
  { name: '晟通牡丹舸', value: 199, state: -1 },
  { name: '和泓梅溪四季', value: 222, state: -1 },
  { name: '湖南商学院', value: 256, state: 1 },
  { name: '荷塘小区', value: 199, state: -1 },
  { name: '金盛建材批发市场', value: 222, state: 1 },
  { name: '金谷大厦', value: 200, state: 1 },
  { name: '望城坡环球家居超市', value: 256, state: 1 },
  { name: '长沙鸿天酒店', value: 199, state: -1 },
  { name: '岳麓广场', value: 222, state: 1 },
  { name: '五一广场', value: 200, state: 1 },
  { name: '名汇达商务楼', value: 290, state: 1 },
  { name: '嘉顿新天地', value: 290, state: 1 },
  { name: '万代大酒店', value: 222, state: -1 },
  { name: '新大新大厦', value: 244, state: 1 },
  { name: '苏宁易购', value: 200, state: 1 },
  { name: '天心区人民医院', value: 156, state: 1 },
  { name: '三王丽都', value: 299, state: -1 },
  { name: '华远华中心', value: 222, state: 1 },
  { name: '下河街大市场', value: 200, state: 1 },
  { name: '银河大厦', value: 200, state: 1 },
  { name: '黄兴广场', value: 220, state: 1 },
  { name: '西罗亚商厦', value: 230, state: 1 },
  { name: '化龙池', value: 222, state: -1 },
  { name: '市中医医院', value: 244, state: 1 },
  { name: '湘亚大厦', value: 200, state: 1 },
  { name: '冶金大厦', value: 256, state: 1 },
  { name: '裕园小区', value: 199, state: -1 },
  { name: '景泰雅园', value: 222, state: -1 },
  { name: '金满地商业街', value: 222, state: 1 },
  { name: '长沙青少年宫', value: 244, state: 1 },
  { name: '万达总部国际', value: 200, state: 1 },
  { name: '潮宗御苑', value: 156, state: -1 },
  { name: '湖南中医附二', value: 299, state: 1 },
  { name: '风帆广场', value: 222, state: 1 },
  { name: '复地崑玉国际', value: 200, state: 1 },
  { name: '华盛新外滩', value: 200, state: 1 },
  { name: '长沙市中心医院', value: 220, state: 1 },
  { name: '兴汉华庭', value: 230, state: -1 },
  { name: '湘雅医学院', value: 222, state: 1 },
  { name: '维也纳国际酒店1', value: 244, state: -1 },
  { name: 'CFC富兴时代', value: 200, state: 1 },
  { name: '锦绣华天', value: 256, state: -1 },
  { name: '北辰三角洲', value: 199, state: -1 },
  { name: '北辰三角洲A区', value: 222, state: -1 },
  { name: '北辰三角洲C区', value: 256, state: -1 },
  { name: '科大佳园-南区', value: 199, state: -1 },
  { name: '科大佳园-北区', value: 222, state: -1 },
  { name: '四方商贸城', value: 200, state: 1 },
  { name: '香樟雅郡', value: 256, state: -1 },
  { name: '世纪金源', value: 199, state: 1 },
  { name: '顺天黄金海岸', value: 222, state: -1 },
  { name: '顺天北国风光', value: 200, state: -1 },
  { name: '湘江世纪城', value: 290, state: -1 },
  { name: '恒大御景', value: 290, state: -1 },
  { name: '世界之窗', value: 222, state: 1 },
  { name: '中南汽车世界', value: 244, state: 1 },
  { name: '星沙汽配城', value: 200, state: 1 },
  { name: '长沙火车站', value: 156, state: 1 },
  { name: '华瑞大厦', value: 156, state: -1 },
  { name: '阿波罗商业广场', value: 156, state: 1 },
  { name: '三湘南湖大市场', value: 156, state: 1 },
  { name: '长沙大厦', value: 156, state: 1 },
  { name: '华天大酒店', value: 156, state: -1 },
  { name: '科佳商业广场', value: 156, state: 1 },
  { name: '友阿总部', value: 156, state: 1 },
  { name: '锦泰广场', value: 299, state: 1 },
  { name: '三湘南湖大市场', value: 222, state: 1 },
  { name: '高桥建材市场', value: 200, state: 1 },
  { name: '维也纳国际酒店2', value: 200, state: -1 },
  { name: '高桥大市场', value: 220, state: 1 },
  { name: '火车南站', value: 230, state: 1 },
  { name: '红星井湾子家居广场', value: 222, state: 1 },
  { name: '星语林', value: 244, state: -1 },
  { name: '鑫远国际', value: 200, state: -1 },
  { name: '保利花园', value: 256, state: -1 },
  { name: '湘水熙园', value: 199, state: -1 },
  { name: '鑫远香山', value: 222, state: -1 },
  { name: '湘雅二医院', value: 299, state: 1 },
  { name: '长沙市传染病医院', value: 222, state: 1 },
  { name: '定王大厦', value: 200, state: 1 },
  { name: '汇富中心', value: 200, state: 1 },
  { name: '贺龙体育场', value: 220, state: 1 },
  { name: '友谊商城', value: 230, state: 1 },
  { name: '国贸金融中心', value: 222, state: 1 },
  { name: '运达国际广场', value: 244, state: 1 },
  { name: '银华大厦', value: 200, state: 1 },
  { name: '维也纳酒店', value: 256, state: -1 },
  { name: '湖南省体育场', value: 199, state: 1 },
  { name: '泊富国际广场', value: 222, state: 1 },
  { name: '绿地中心', value: 256, state: 1 },
  { name: '马王堆商贸城', value: 199, state: 1 },
  { name: '好润佳购物中心', value: 222, state: 1 },
  { name: '湖南环保涂料城', value: 200, state: 1 },
  { name: '马王堆防水建材市场', value: 256, state: 1 },
  { name: '万家丽小区', value: 199, state: -1 },
  { name: '君悦香邸', value: 222, state: -1 },
  { name: '长沙丽星汽车维修服务有限公司', value: 200, state: 1 },
  { name: '北辰府', value: 290, state: -1 },
  { name: '湖南省儿童医院', value: 290, state: 1 },
  { name: '光大发展大厦', value: 222, state: 1 },
  { name: '汇金国际银座', value: 244, state: 1 },
  { name: '湖南省第二医院', value: 200, state: 1 },
  { name: '顺天国际金融中心', value: 156, state: 1 },
  { name: '湖南邮电职业技术学院', value: 156, state: 1 },
  { name: '中建江山一号', value: 156, state: -1 },
  { name: '保利国际广场', value: 156, state: -1 },
  { name: '荣盛花语书苑', value: 156, state: -1 },
  { name: '望月公园', value: 156, state: 1 },
  { name: '圆泰国际广场', value: 156, state: 1 },
  { name: '望月湖文明小区', value: 156, state: -1 },
  { name: '长沙玛丽亚妇产医院', value: 299, state: 1 },
  { name: '岳麓区政府', value: 222, state: 1 },
  { name: '长沙银行', value: 200, state: 1 },
  { name: '阳光丽城', value: 200, state: -1 },
  { name: '钰龙天下', value: 220, state: -1 },
  { name: '滨江农贸综合市场', value: 230, state: 1 },
  { name: '宇洋西海广场', value: 222, state: 1 },
  { name: '长沙华文香枫酒店', value: 244, state: -1 },
  { name: '万科城', value: 200, state: -1 },
  { name: '万国城', value: 256, state: -1 },
  { name: '美利新世界', value: 199, state: -1 },
  { name: '长沙大学附属中学', value: 222, state: 1 },

  { name: '金麓国际商务广场', value: 222, state: 1 },
  { name: '长沙湘江爱尔眼科医院', value: 244, state: 1 },
  { name: '咸嘉新村', value: 200, state: -1 },
  { name: '润泽园', value: 156, state: -1 },
  { name: '新天地大厦', value: 156, state: 1 },
  { name: '湖南财经学院', value: 156, state: 1 },
  { name: '步步高生活广场', value: 156, state: 1 },
  { name: '王府花园', value: 156, state: -1 },
  { name: '南湖建材城', value: 156, state: 1 },
  { name: '毛家桥大市场', value: 156, state: 1 },
  { name: '长城万富汇', value: 156, state: 1 },
  { name: '麦德龙', value: 299, state: 1 },
  { name: '麓山国际', value: 222, state: -1 },
  { name: '长沙教育学院', value: 200, state: 1 },
  { name: '岳麓区人力资源市场', value: 200, state: 1 },
  { name: '景秀江山', value: 220, state: -1 },
  { name: '莱茵城', value: 230, state: -1 },
  { name: '欣胜园', value: 222, state: -1 },
  { name: '时代广场', value: 244, state: 1 },
  { name: '奥克斯缔壹城', value: 200, state: -1 },
  { name: '阳光晶城', value: 256, state: -1 },
  { name: '湖南中医药附一', value: 199, state: 1 },
  { name: '蓝湖国际广场', value: 222, state: 1 },
  { name: '沁园春', value: 222, state: -1 },
  { name: '华润城市海岸', value: 244, state: -1 },
  { name: '湖韵佳苑', value: 200, state: -1 },
  { name: '凯乐国际城', value: 256, state: -1 },
  { name: '金色屋顶', value: 199, state: -1 },
  { name: '古汉国际广场', value: 222, state: 1 }
]

var convertData = function (data, n) {
  var res = []
  for (var i = 0; i < data.length; i++) {
    var geoCoord = geoCoordMap[data[i].name]
    if (geoCoord) {
      //res.push(geoCoord.concat(data[i].value + (Math.random() - 0.5) * n))
      res.push(geoCoord.concat(data[i].value + 100 + n * data[i].state))
    }
  }
  return res
}
var title = ''
var bmap = {
  center: [112.963472, 28.219072]
}
var timeline = {
  autoPlay: true,
  data: ['6:00', '8:00', '10:00', '12:00', '14:00', '16:00', '18:00', '20:00', '22:00', '24:00']
}
var options = [
  {
    series: [{
      data: convertData(value, -200)
    }]
  },
  {
    series: [{
      data: convertData(value, -100)
    }]
  },
  {
    series: [{
      data: convertData(value, 200)
    }]
  },
  {
    series: [{
      data: convertData(value, 200)
    }]
  },
  {
    series: [{
      data: convertData(value, 100)
    }]
  },
  {
    series: [{
      data: convertData(value, 200)
    }]
  },
  {
    series: [{
      data: convertData(value, 100)
    }]
  },
  {
    series: [{
      data: convertData(value, -100)
    }]
  },
  {
    series: [{
      data: convertData(value, -200)
    }]
  },
  {
    series: [{
      data: convertData(value, -200)
    }]
  }
]

export default {
  baseOption: {
    title: {
      text: "城市交通态势监测大数据看板",
      target: "blank", //'self' 当前窗口打开，'blank' 新窗口打开
      padding: 5, // 标题内边距 5  [5, 10]  [5,10,5,10]
      left: 'center',
      top: 10,
      textStyle: {
        color: "#333333", //文字颜色
        fontStyle: "normal", // italic斜体  oblique倾斜
        fontWeight: "800", // 文字粗细bold   bolder   lighter  100 | 200 | 300 | 400...
        fontFamily: "Microsoft YaHei-Bold, Microsoft YaHei", // 字体系列
        fontSize: 30, // 字体大小
      }
    },
    timeline: {
      bottom: 10,
      autoPlay: timeline.autoPlay,
      data: timeline.data,
      axisType: 'category',
      padding: [5, 5, 5, 5],
      playInterval: 1500,
      lineStyle: { color: '#4D4D4D' },
      controlPosition: 'right',
      controlStyle: {
        color: '#4D4D4D',
        borderColor: '#4D4D4D'
      },
      label: {
        normal: {
          textStyle: {
            color: '#4D4D4D',
            fontSize: 13,
            fontWeight: '800'
          }
        }
      }
    },
    bmap: {
      center: bmap.center,
      zoom: 14,
      roam: true,
      mapStyle: {
        styleJson: [
          {
            'featureType': 'land', // 调整土地颜色
            'elementType': 'geometry',
            'stylers': {
              'color': '#081734'
            }
          },
          {
            'featureType': 'building', // 调整建筑物颜色
            'elementType': 'geometry',
            'stylers': {
              'color': '#04406F'
            }
          },
          {
            'featureType': 'building', // 调整建筑物标签是否可视
            'elementType': 'labels',
            'stylers': {
              'visibility': 'off'
            }
          },
          {
            'featureType': 'highway', // 调整高速道路颜色
            'elementType': 'geometry',
            'stylers': {
              'color': '#015B99'
            }
          },
          {
            'featureType': 'highway', // 调整高速名字是否可视
            'elementType': 'labels',
            'stylers': {
              'visibility': 'off'
            }
          },
          {
            'featureType': 'arterial', // 调整一些干道颜色
            'elementType': 'geometry',
            'stylers': {
              'color': '#003051'
            }
          },
          {
            'featureType': 'arterial',
            'elementType': 'labels',
            'stylers': {
              'visibility': 'off'
            }
          },
          {
            'featureType': 'green',
            'elementType': 'geometry',
            'stylers': {
              'visibility': 'off'
            }
          },
          {
            'featureType': 'water',
            'elementType': 'geometry',
            'stylers': {
              'color': '#044161'
            }
          },
          {
            'featureType': 'subway', // 调整地铁颜色
            'elementType': 'geometry.stroke',
            'stylers': {
              'color': '#003051'
            }
          },
          {
            'featureType': 'subway',
            'elementType': 'labels',
            'stylers': {
              'visibility': 'off'
            }
          },
          {
            'featureType': 'railway',
            'elementType': 'geometry',
            'stylers': {
              'visibility': 'off'
            }
          },
          {
            'featureType': 'railway',
            'elementType': 'labels',
            'stylers': {
              'visibility': 'off'
            }
          },
          {
            'featureType': 'all', // 调整所有的标签的边缘颜色
            'elementType': 'labels.text.stroke',
            'stylers': {
              'color': '#313131'
            }
          },
          {
            'featureType': 'all', // 调整所有标签的填充颜色
            'elementType': 'labels.text.fill',
            'stylers': {
              'color': '#FFFFFF'
            }
          },
          {
            'featureType': 'manmade',
            'elementType': 'geometry',
            'stylers': {
              'visibility': 'off'
            }
          },
          {
            'featureType': 'manmade',
            'elementType': 'labels',
            'stylers': {
              'visibility': 'off'
            }
          },
          {
            'featureType': 'local',
            'elementType': 'geometry',
            'stylers': {
              'visibility': 'off'
            }
          },
          {
            'featureType': 'local',
            'elementType': 'labels',
            'stylers': {
              'visibility': 'off'
            }
          },
          {
            'featureType': 'subway',
            'elementType': 'geometry',
            'stylers': {
              'lightness': -65
            }
          },
          {
            'featureType': 'railway',
            'elementType': 'all',
            'stylers': {
              'lightness': -40
            }
          },
          {
            'featureType': 'boundary',
            'elementType': 'geometry',
            'stylers': {
              'color': '#8b8787',
              'weight': '1',
              'lightness': -29
            }
          }]
      }
    },
    visualMap: {
      min: 0,
      max: 500,
      splitNumber: 5,
      inRange: {
        color: ['blue', 'green', 'yellow', '#db511f']
      },
      textStyle: {
        color: '#4D4D4D',
        fontWeight: 'bolder'
      },
      bottom: 10,
      left: 'right',
      right: 20
    },
    series: [{
      type: 'heatmap',
      mapType: 'world',
      coordinateSystem: 'bmap',
      blurSize: 25
    }]
  },
  options: options
}