<template>
  <div class="heatMapDom">
    <div :class="className" :id="id" :style="{ height: height, width: width }" />
  </div>
</template>
<script>
// import echarts from 'echarts/lib/echarts'
// 修改为
import * as echarts from 'echarts'
import option from './js/map-option'
import { loadBMap } from './js/map.js'
require('echarts/extension/bmap/bmap')
export default {
  props: {
    className: {
      type: String,
      default: 'heatMapCss'
    },
    id: {
      type: String,
      default: 'heatMap'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      chart: null
    }
  },
  mounted () {
    this.$nextTick(() => {
      loadBMap('53oVIOgmSIejwV7EfphPgTynOZbIiVYu').then(() => {
        this.init()
      })
    })
  },
  methods: {
    init () {
      this.chart = echarts.init(document.getElementById(this.id))
      console.log(option, '==数据')
      this.chart.setOption(option)
    }
  }
}
</script>
<style>
.heatMapDom {
  width: 100%;
  height: 100%;
}

.BMap_noprint,
.anchorBL {
  display: none;
}
</style>
